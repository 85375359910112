import React from 'react';
import { Link, withRouter } from 'react-router-dom';

function NavBar(props) {
  return (
    <div className='navbar'>
      <div className='navbar-box1'>
        <Link to='/' className='Link'>
          <img src='logo.png' alt='Logo' />
        </Link>
        <Link to='/' className='Link'>
          <h1>Web App</h1>
        </Link>
      </div>
      <div className='navbar-box2'>
        <a href='https://marcwittwer.com/'> &copy; 2019 - Marc Wittwer</a>
        <a href='#'>+</a>
        <a href='#'>+</a>
      </div>
      <div className='navbar-box3'>
        <p className=''>test.user@mail.ch</p>
        <button>Logout</button>
      </div>
    </div>
  );
}
// export default withRouter(NavBar);

export default NavBar;
