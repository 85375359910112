import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function EventsList(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>Erfasste Veranstaltungen (Entwurf)</h1>
        <h2>Jahr 2020</h2>
        <div className='cards-container'>
          <Link to='/event-einsatz'>
            <div class='card'>
              <img src='event_logo.png' alt='Logo' />
              <div class='container'>
                <h4>Openair Frauenfeld</h4>
                <p>11.07.2020</p>
              </div>
              <button type='button'>Mehr >></button>
            </div>
          </Link>
          <Link to='/event-einsatz'>
            <div class='card'>
              <img src='event_logo.png' alt='Logo' />
              <div class='container'>
                <h4>Openair Frauenfeld</h4>
                <p>11.07.2020</p>
              </div>
              <button type='button'>Mehr >></button>
            </div>
          </Link>
          <Link to='/event-einsatz'>
            <div class='card'>
              <img src='event_logo.png' alt='Logo' />
              <div class='container'>
                <h4>Openair Frauenfeld</h4>
                <p>11.07.2020</p>
              </div>
              <button type='button'>Mehr >></button>
            </div>
          </Link>
          <Link to='/event-einsatz'>
            <div class='card'>
              <img src='event_logo.png' alt='Logo' />
              <div class='container'>
                <h4>Openair Frauenfeld</h4>
                <p>11.07.2020</p>
              </div>
              <button type='button'>Mehr >></button>
            </div>
          </Link>
          <Link to='/event-einsatz'>
            <div class='card'>
              <img src='event_logo.png' alt='Logo' />
              <div class='container'>
                <h4>Openair Frauenfeld</h4>
                <p>11.07.2020</p>
              </div>
              <button type='button'>Mehr >></button>
            </div>
          </Link>
          <Link to='/event-einsatz'>
            <div class='card'>
              <img src='event_logo.png' alt='Logo' />
              <div class='container'>
                <h4>Openair Frauenfeld</h4>
                <p>11.07.2020</p>
              </div>
              <button type='button'>Mehr >></button>
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EventsList;
