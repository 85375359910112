import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';

import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import MainMenu from './components/MainMenu';
import AnmeldungSanD from './components/AnmeldungSanD';
import Risikobeurteilung from './components/Risikobeurteilung';
import CreateEvent from './components/CreateEvent';
import EventsList from './components/EventsList';
import MemberList from './components/MemberList';
import MaterialList from './components/MaterialList';
import Invoice from './components/Invoice';
import EinsatzModus from './components/EinsatzModus';
import StatistikVerein from './components/StatistikVerein';
import { stat } from 'fs';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <SideBar />
        <div className='main-container'>
          <Route exact path='/' component={MainMenu} />
          <Route exact path='/anmeldung-sandienst' component={AnmeldungSanD} />
          <Route
            exact
            path='/risikobeurteilung'
            component={Risikobeurteilung}
          />
          <Route exact path='/veranstaltung-planen' component={CreateEvent} />
          <Route exact path='/veranstaltungen-liste' component={EventsList} />
          <Route exact path='/organigramm' component={MemberList} />
          <Route exact path='/material-inventar' component={MaterialList} />
          <Route exact path='/rechnungen' component={Invoice} />
          <Route exact path='/event-einsatz' component={EinsatzModus} />
          <Route exact path='/statistik' component={StatistikVerein} />
        </div>
      </React.Fragment>
    );
  }
}

export default App;
