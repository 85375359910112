import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function Invoice(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>Rechnungen Übersicht</h1>
        <h2>Ausgestellte Rechnungen</h2>
        <div className='mat-cards-container'>
          <div class='mat-card'>
            <p>Status: Bezahlt</p>

            <h4>Veranstalter XY</h4>
            <p>10.05.2019</p>
            <button type='button'>Drucken</button>
          </div>
          <div class='mat-card'>
            <p>Status: Bezahlt</p>

            <h4>Veranstalter XY</h4>
            <p>10.05.2019</p>
            <button type='button'>Drucken</button>
          </div>
          <div class='mat-card'>
            <p>Status: ausstehend</p>

            <h4>Veranstalter XY</h4>
            <p>10.05.2019</p>
            <button type='button'>Drucken</button>
          </div>
        </div>
        <h2>Neue Rechnung erstellen</h2>

        <button
          type='button'
          style={{ width: '150px', padding: '10px', margin: '10px' }}
        >
          + Erstellen
        </button>
      </div>
    </React.Fragment>
  );
}

export default Invoice;
