import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

function SideBar(props) {
  return (
    <div className='sidebar'>
      <ul>
        <li>
          <Link to='/' className='Link'>
            <FontAwesomeIcon icon={faAngleLeft} className='list-icon' />
            Zurück
          </Link>
        </li>
        {/* <li>
          <Link to='/a' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />B
          </Link>
        </li>
        <li>
          <Link to='/' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />C
          </Link>
        </li>
        <li>
          <Link to='/' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />D
          </Link>
        </li> */}
      </ul>
    </div>
  );
}
// export default withRouter(NavBar);

export default SideBar;
