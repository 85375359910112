import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function MemberList(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>
          Organisation - Mitglieder SV Frauenfeld
        </h1>
        <div className='memberlist-content'>
          <h2>Gliederung</h2>
          <ul className='level-1'>
            <li>
              <p>Schweizer Samariterbund</p>
              <ul>
                <li className='level-2'>
                  <p>Kantonalverband Thurgau</p>
                  <ul>
                    <li className='level-3'>
                      <p>SV Arbon (35 Mitglieder)</p>
                    </li>
                    <li className='level-3'>
                      <p>SV Kreuzlingen (45 Mitglieder)</p>
                    </li>
                    <li className='level-3'>
                      <p>
                        <span>SV Frauenfeld (50 Mitglieder)</span>
                      </p>
                    </li>
                    <li className='level-3'>
                      <p>...</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <h2>Personelles (inkl. Übersicht Ausbildungen Samariter)</h2>
          <ul className='level-1'>
            <li>
              <p>Leitung</p>
              <ul>
                <li className='level-2'>
                  <p>Max Muster 1 (Präsident)</p>
                </li>
                <li className='level-2'>
                  <p>Max Muster 2 (Vize-Präsident)</p>
                </li>
                <li className='level-2'>
                  <p>Max Muster 3 (Aktuar)</p>
                </li>
                <li className='level-2'>
                  <p>Max Muster 4 (Materialverwalter)</p>
                </li>
                <li className='level-2'>
                  <p>Max Muster 5 (Leiter techn. Ausschuss)</p>
                </li>
                <li className='level-2'>
                  <p>...</p>
                </li>
              </ul>
            </li>
            <li>
              <p>Samariter</p>
              <ul>
                <li className='level-2'>
                  <p>
                    Max Muster 11 <span>(Admin-User)</span>
                  </p>
                </li>
                <li className='level-2'>
                  <p>
                    Max Muster 12 <span>(Admin-User)</span>
                  </p>
                </li>
                <li className='level-2'>
                  <p>
                    Max Muster 13 <span>BLS-AED Kurs I / II</span>
                  </p>
                </li>
                <li className='level-2'>
                  <p>
                    Max Muster 14 <span>BLS-AED Kurs I </span>
                  </p>
                </li>
                <li className='level-2'>
                  <p>
                    Max Muster 15 <span>BLS-AED Kurs I / II</span>
                  </p>
                </li>
                <li className='level-2'>
                  <p>...</p>
                </li>
              </ul>
            </li>
          </ul>
          {/* <form className='form-1'>
            <h2> Formular zum Ausfüllen</h2>
            <h3>Veranstaltungsinfos:</h3>
            <textarea rows='4' cols='50' name='comment' form='usrform' />
          </form>

          <form className='form-1'>
            <h2> Auswahl des Samaritervereins</h2>
            <select>
              <option value='volvo'>Bitte auswählen</option>
              <option value='saab'>SV Aarbon</option>
              <option value='mercedes'>SV Kreuzlingen</option>
              <option value='audi'>SV Frauenfeld</option>
            </select>
            <h2> Samariter Einteilung</h2>
            <ul>
              <li>
                <label>
                  <input type='checkbox' />
                  <span>Max Muster 1</span>
                </label>
              </li>
              <li>
                <label>
                  <input type='checkbox' />
                  <span>Max Muster 2</span>
                </label>
              </li>
              <li>
                <label>
                  <input type='checkbox' />
                  <span>Max Muster 3</span>
                </label>
              </li>
              <li>
                <label>
                  <input type='checkbox' />
                  <span>Max Muster 4</span>
                </label>
              </li>
            </ul>
            <p>
              Die Samariter werden beim Abschicken dieses Formulares über ihren
              Einsatz per Mail benachrichtigt.
            </p>
            <h2> Schichtplan</h2>
            <table>
              <tr>
                <th>Samariter</th>
                <th>1400-1800</th>
                <th>1800-2200</th>
                <th>2200-0200</th>
                <th>...</th>
              </tr>
              <tr>
                <td>Max Muster 1</td>
                <td>OOO</td>
                <td>-</td>
                <td>OOO</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Max Muster 2</td>
                <td>OOO</td>
                <td>-</td>
                <td>OOO</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Max Muster 3</td>
                <td>-</td>
                <td>OOO</td>
                <td>-</td>
                <td>OOO</td>
              </tr>
              <tr>
                <td>Max Muster 4</td>
                <td>-</td>
                <td>OOO</td>
                <td>-</td>
                <td>OOO</td>
              </tr>
            </table>
            <p></p>
            <h2>
              Anhänge<button type='button'>+ Hochladen</button>
            </h2>
            <span>Lageplan.pdf</span>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <button type='button'>Veranstaltung erstellen</button>
          </form> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MemberList;
