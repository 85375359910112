import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function MaterialList(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>Inventar Material</h1>
        <div className='mat-cards-container'>
          <div class='mat-card'>
            <h4>Verband (2m)</h4>
            <p>Stückzahl: 4</p>
            <button type='button'>Bearbeiten >></button>
          </div>
          <div class='mat-card'>
            <h4>Pflaster </h4>
            <p>Stückzahl: 4</p>
            <button type='button'>Bearbeiten >></button>
          </div>
          <div class='mat-card'>
            <h4>Aspirin </h4>
            <p>Stückzahl: 4</p>
            <button type='button'>Bearbeiten >></button>
          </div>
          <div class='mat-card'>
            <h4>Schere </h4>
            <p>Stückzahl: 4</p>
            <button type='button'>Bearbeiten >></button>
          </div>
          <div class='mat-card'>
            <h4>Schnellverband </h4>
            <p>Stückzahl: 4</p>
            <button type='button'>Bearbeiten >></button>
          </div>
          <div class='mat-card'>
            <h4>Dreieckstuch</h4>
            <p>Stückzahl: 4</p>
            <button type='button'>Bearbeiten >></button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MaterialList;
