import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function AnmeldungSanD(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>Anmeldung Sanitätsdienst (Entwurf)</h1>
        <div className='anmeldung-sanD-content'>
          <form className='form-1'>
            <h2> Formular zum Ausfüllen durch Veranstalter</h2>
            <p>Veranstalter:</p>
            <input type='text' />
            <p>Art der Veranstaltung:</p>
            <input type='text' />
            <p>Ort der Veranstaltung:</p>
            <input type='text' />
            <p>Datum:</p>
            <input type='text' />
            <button type='button'>Speichern</button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AnmeldungSanD;
