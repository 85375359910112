import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function Risikobeurteilung(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>
          Risikobeurteilung - Sanitätsdienst Auftrag (Entwurf)
        </h1>
        <div className='anmeldung-sanD-content'>
          <form className='form-1'>
            <h2> Formular zum Ausfüllen</h2>
            <p>Veranstalter:</p>
            <input type='text' />
            <p>Aktiv Beteiligte:</p>
            <input type='text' />
            <p>Zuschauer:</p>
            <input type='text' />
            <button type='button'>Auswerten</button>
          </form>

          <form className='form-1'>
            <p>__________________________</p>
            <h2> Auswertung der Beurteilung</h2>
            <p>
              Aus der Risikobeurteilung geht hervor, dass es sich um eine
              Veranstaltung der <span>Stufe 3</span> handelt.
            </p>
            <p>
              <span>3 Samariter</span> mit mit CPR- oder BLS-AED-Ausweis, der
              nicht älter als zwei Jahre ist.
            </p>

            <button type='button'>Ausdrucken</button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Risikobeurteilung;
