import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function EinsatzModus(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>Einsatzmodus</h1>
        <p style={{ padding: '10px' }}>
          Dieser Modus repräsentiert den Startbildschirm für einen Samariter an
          der Veranstaltung.
        </p>
        <h2>Veranstaltung: Openair Frauenfeld</h2>
        <div className='einsatz-cards-container'>
          <div class='einsatz-card'>
            <h4> Journal Eintrag</h4>
          </div>
          <div class='einsatz-card'>
            <h4> + Neuer Patient</h4>
          </div>
          <div class='einsatz-card'>
            <h4> Patient ändern</h4>
          </div>
          <div class='einsatz-card'>
            <h4> Schichtplan</h4>
          </div>
          <div class='einsatz-card'>
            <h4> Veranstaltung Info</h4>
          </div>
        </div>
        <h2>Telefonliste</h2>
        <p className='tel-list'>Max Muster 079 789 78 78</p>
        <p className='tel-list'>Max Muster 079 789 78 78</p>
        <p className='tel-list'>Max Muster 079 789 78 78</p>
        <p className='tel-list'>Max Muster 079 789 78 78</p>
        <p className='tel-list'>Max Muster 079 789 78 78</p>
      </div>
    </React.Fragment>
  );
}

export default EinsatzModus;
