import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function MainMenu(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>Hauptmenu</h1>
        <div className='main-menu-content'>
          <h3>Neue Veranstaltung</h3>
          <Link to='/anmeldung-sandienst' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />
            1. Anmeldung Sanitätsdienst (von Veranstalter auszufüllen)
          </Link>
          <Link to='/risikobeurteilung' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />
            2. Risikobeurteilung Veranstaltung
          </Link>
          <Link to='/veranstaltung-planen' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />
            3. Veranstaltung planen
          </Link>
          <h3>Interne Funktionen</h3>
          <Link to='/veranstaltungen-liste' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />
            Erfasste Veranstaltungen
          </Link>
          <Link to='/organigramm' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />
            Mitglieder Samariterverein - Organigramm
          </Link>
          <Link to='/material-inventar' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />
            Material Verein
          </Link>
          <Link to='/statistik' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />
            Statistik Verein
          </Link>
          <Link to='/rechnungen' className='Link'>
            <FontAwesomeIcon icon={faAngleRight} className='list-icon' />
            Rechnungen{' '}
          </Link>
          <Link to='/event-einsatz' className='Link'>
            <div>In den Einsatzmodus wechseln >>></div>
          </Link>{' '}
        </div>
      </div>
    </React.Fragment>
  );
}
// export default withRouter(NavBar);

export default MainMenu;
