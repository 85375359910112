import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function StatistikVerein(props) {
  return (
    <React.Fragment>
      <div className='main-menu'>
        <h1 className='main-menu-header'>Statistik SV Frauenfeld</h1>
        <p>
          Hier stehen Informationen und Zahlen zu vergangenen Veranstaltungen.
        </p>
        <p>...</p>
        <p>...</p>
        <p>...</p>
        <p>...</p>
      </div>
    </React.Fragment>
  );
}

export default StatistikVerein;
